import React, { ChangeEvent, ReactElement } from "react";
import { useFormikContext } from "formik";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FieldWrapper } from "./FieldWrapper";

interface Props {
  name: string;
  type?: string;
  placeholder?: string;
  label?: string;
  variant?: any;
  borderColor?: string;
  borderHoverColor?: string;
  isDisabled?: boolean;
  callback?: (value: string) => void;
  icon?: ReactElement | string;
  accept?: string;
  height?: string;
  width?: string;
  textAlign?: string;
}

export function InputField({
  name,
  type = "text",
  placeholder,
  label,
  variant = "",
  borderColor = "brand.blue",
  borderHoverColor = "brand.lightBlue",
  textAlign,
  isDisabled = false,
  callback = () => {},
  icon = null,
  accept,
  width,
  height,
}: Props): ReactElement {
  const formikProps = useFormikContext();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    formikProps.setFieldValue(name, event.target.value);
    callback(event.target.value);
  };

  return (
    <FieldWrapper name={name} label={label}>
      <InputGroup>
        {
          // eslint-disable-next-line react/no-children-prop
          icon == null ? null : <InputLeftElement pointerEvents="none" children={icon} />
        }
        <Input
          isDisabled={isDisabled}
          value={formikProps.values[name]}
          // fontWeight={formikProps.values[name] ? "400" : "600"}
          variant={variant}
          id={`Input Field ${name}`}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          accept={accept}
          borderRadius="md"
          borderWidth="2px"
          borderColor={borderColor}
          _hover={{ borderColor: borderHoverColor }}
          height={height}
          width={width}
          textAlign={textAlign}
        />
      </InputGroup>
    </FieldWrapper>
  );
}
