import React, { ReactNode } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Label } from "../styled/Text";

interface Props {
  children: ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  size: string;
}

export function MyModal({ children, title, isOpen, onClose, size }: Props) {
  return (
    <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Label text={title} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
