import { Box } from "@chakra-ui/react";
import React, { ReactElement } from "react";

export function Break({ height }: Props): ReactElement {
  return <Box h={height} />;
}

interface Props {
  height: string;
}
