import { useMediaQuery } from "@chakra-ui/react";
import { useEffect } from "react";

export function isDesktopHook(): boolean {
  return useMediaQuery("(min-width: 768px)")[0];
}

export function pixelSubscribeEvent(location: string, email: string) {
  if (typeof window !== undefined && window.fbq != null) {
    window.fbq("track", "lead", { content_category: location, value: email });
  }
}

export function setupLeaveEvent(apiClient, trackingHistory) {
  useEffect(() => {
    const pageHeight = document.documentElement.offsetHeight - 1000;
    const isDesktop = screen.width > 1200;

    const unloadCallback = () => {
      const history = trackingHistory.finalizeTrackingHistory("leave", "leave");
      apiClient.createWebsiteEvent(history);
    };

    let scrollCallback: () => void;
    if (isDesktop) {
      scrollCallback = () => {
        const heightPercent = scrollY / pageHeight;
        if (heightPercent > 0.16 && heightPercent < 0.36) {
          trackingHistory.addToHistory("viewed_icons");
        } else if (heightPercent > 0.36 && heightPercent < 0.5) {
          trackingHistory.addToHistory("viewed_better_worlds");
        } else if (heightPercent > 0.5 && heightPercent < 0.69) {
          trackingHistory.addToHistory("viewed_promises");
        } else if (heightPercent > 0.69 && heightPercent < 0.83) {
          trackingHistory.addToHistory("viewed_review");
        } else if (heightPercent > 0.83 && heightPercent < 0.9) {
          trackingHistory.addToHistory("viewed_product");
        } else if (heightPercent > 0.9) {
          trackingHistory.addToHistory("viewed_end");
        }
      };
    } else {
      scrollCallback = () => {
        const heightPercent = scrollY / pageHeight;
        if (heightPercent > 0.24 && heightPercent < 0.38) {
          trackingHistory.addToHistory("viewed_icons");
        } else if (heightPercent > 0.38 && heightPercent < 0.5) {
          trackingHistory.addToHistory("viewed_better_worlds");
        } else if (heightPercent > 0.5 && heightPercent < 0.63) {
          trackingHistory.addToHistory("viewed_promises");
        } else if (heightPercent > 0.63 && heightPercent < 0.9) {
          trackingHistory.addToHistory("viewed_review");
        } else if (heightPercent > 0.9 && heightPercent < 0.95) {
          trackingHistory.addToHistory("viewed_showcase");
        } else if (heightPercent > 0.95) {
          trackingHistory.addToHistory("viewed_end");
        }
      };
    }

    const intervalCallback = () => {
      const history = trackingHistory.getHistory();
      const previousTimeSpent = history["time_spent"] || 0;
      trackingHistory.addToHistory("time_spent", previousTimeSpent + 10);
    };

    window.addEventListener("beforeunload", unloadCallback);
    window.addEventListener("scroll", scrollCallback);
    const intervalId = window.setInterval(intervalCallback, 10000);

    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
      window.removeEventListener("scroll", scrollCallback);
      window.clearInterval(intervalId);
    };
  }, []);
}
