import React from "react";
import { BoxProps, Text } from "@chakra-ui/react";

interface Props {
  text: string;
  fontSize?: string;
  rootProps?: BoxProps;
}

export function Title({ text, fontSize = "3rem", ...rootProps }: Props) {
  // latoBold
  return (
    <Text color="black" textAlign="center" fontSize={fontSize} fontFamily="galdeano" fontWeight="500" {...rootProps}>
      {text}
    </Text>
  );
}

export function MinorTitle({ text, ...rootProps }: Props) {
  return (
    <Text color="black" textAlign="center" fontSize={{ base: "2.3rem", lg: "4rem" }} fontFamily="galdeano" fontWeight={600} {...rootProps}>
      {text}
    </Text>
  );
}

export function SubtitleBold({ text, ...rootProps }: Props) {
  return (
    <Text color="black" textAlign="center" fontSize={{ base: "2rem", lg: "2.5rem" }} fontFamily="galdeano" fontWeight="600" {...rootProps}>
      {text}
    </Text>
  );
}

export function Label({ text, ...rootProps }: Props) {
  return (
    <Text fontSize={{ base: "1.1rem", lg: "1.5rem" }} fontFamily="galdeano" fontWeight={600} textAlign="center" color="black" {...rootProps}>
      {text}
    </Text>
  );
}

export function LabelMinor({ text, ...rootProps }: Props) {
  return (
    <Text fontSize="1.3rem" fontFamily="galdeano" color="black" textAlign="center" fontWeight={500}>
      {text}
    </Text>
  );
}

export function Caption({ text, ...rootProps }: Props) {
  return (
    <Text fontSize={{ base: "1.1rem", lg: "1.8rem" }} fontFamily="Linotte" textAlign="center" {...rootProps}>
      {text}
    </Text>
  );
}

export function Standard({ text, ...rootProps }: Props) {
  return (
    <Text fontSize={{ base: "1rem", lg: "1.4rem" }} fontFamily="linotte" fontWeight={300} {...rootProps}>
      {text}
    </Text>
  );
}
