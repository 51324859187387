import React, { ReactElement } from "react";
import { useBoolean } from "@chakra-ui/react";
import { MyModal } from "../common/MyModal";
import { SignUpForm } from "./SignUp";
import { SignInForm } from "./SignIn";

interface AuthModalProps {
  startSignUp: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export function AuthModal({ startSignUp, isOpen, onClose }: AuthModalProps): ReactElement {
  const [signUp, setSignUp] = useBoolean(startSignUp);
  const title = signUp ? "Join the Party" : "Welcome back!";
  return (
    <MyModal title={title} isOpen={isOpen} onClose={onClose} size="lg">
      {signUp ? (
        <SignUpForm setSignUp={setSignUp.off} onSubmitCallback={onClose} />
      ) : (
        <SignInForm setSignUp={setSignUp.on} onSubmitCallback={onClose} />
      )}
    </MyModal>
  );
}
