import { Box, Button, HStack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { isDesktopHook } from "../../Hooks";
import { VisibleResonsive } from "../common/VisibleResponsive";
import { useAuthState } from "../../context/AuthState";

export function AuthButtons() {
  const isDesktop = isDesktopHook();
  const authState = useAuthState();

  return (
    <HStack spacing={isDesktop ? "1vw" : "2vw"}>
      <Button type="submit" w="100px" h="32px" colorScheme="blue">
        Submit
      </Button>
      <VisibleResonsive
        smallShow={
          <Box w="32px" h="32px" _hover={{ cursor: "pointer" }} onClick={authState.amazonLogin}>
            <StaticImage src="../../images/amazonMobile.png" alt="amazon" />
          </Box>
        }
        largeShow={
          <Box w="76px" h="32px" _hover={{ cursor: "pointer" }} onClick={authState.amazonLogin}>
            <StaticImage src="../../images/amazonDesktop.png" alt="amazon" />
          </Box>
        }
      />
      <VisibleResonsive
        smallShow={
          <Box w="46px" h="46px" _hover={{ cursor: "pointer" }} onClick={authState.googleLogin}>
            <StaticImage src="../../images/googleMobile.png" alt="goolge" />
          </Box>
        }
        largeShow={
          <Box w="191px" h="46px" _hover={{ cursor: "pointer" }} onClick={authState.googleLogin}>
            <StaticImage src="../../images/googleDesktop.png" alt="google" />
          </Box>
        }
      />
    </HStack>
  );
}
