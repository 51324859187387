import React, { ReactElement, ReactNode } from "react";
import { useFormikContext } from "formik";
import { Box, Text } from "@chakra-ui/react";
import { LabelMinor } from "../../styled/Text";

interface Props {
  name: string;
  children: ReactNode;
  label?: string;
}

export function FieldWrapper({ name, children, label }: Props): ReactElement {
  const formikProps = useFormikContext();

  return (
    <Box>
      {label ? <LabelMinor text={label} /> : null}
      {children}
      <Text color="red" fontSize="1.1rem" textAlign="center" fontFamily="linotte" fontWeight={500}>
        {formikProps.errors[name]}
      </Text>
    </Box>
  );
}
