import React, { ReactElement } from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  smallShow: ReactElement;
  largeShow: ReactElement;
}

export function VisibleResonsive({ smallShow, largeShow }: Props): ReactElement {
  return (
    <Box>
      <Box display={{ base: "block", lg: "none" }}>{smallShow}</Box>
      <Box display={{ base: "none", lg: "block" }}>{largeShow}</Box>
    </Box>
  );
}
