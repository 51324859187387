import React, { ReactElement } from "react";
import { Formik, Form } from "formik";
import { Button } from "@chakra-ui/react";
import { InputField } from "../common/form_fields/InputField";
import { Break } from "../styled/Break";
import { useAuthState } from "../../context/AuthState";
import { AuthButtons } from "./AuthButtons";

interface Props {
  onSubmitCallback?: () => void;
  setSignUp?: () => void;
}

export function SignUpForm({
  setSignUp = () => {},
  onSubmitCallback = () => {},
}: Props): ReactElement {
  const authState = useAuthState();

  function onSubmit(values, actions) {
    authState.signUp(values.email, values.password).then(() => {
      actions.setSubmitting(false);
      onSubmitCallback();
    });
  }

  return (
    <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
      <Form>
        <InputField name="email" type="email" placeholder="Email" />
        <Break height="5vh" />
        <InputField name="password" type="password" placeholder="Password" />
        <Break height="5vh" />
        <InputField name="confirmPassword" type="password" placeholder="Confirm Password" />
        <Break height="2vh" />
        <Button height="0.8rem" variant="ghost" onClick={setSignUp} fontSize="0.7rem" color="gray">
          Already have an account?
        </Button>
        <Break height="2vh" />
        <AuthButtons />
      </Form>
    </Formik>
  );
}
