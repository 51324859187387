import { Box } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  hide?: boolean;
  children: ReactNode;
}

export function Hide({ hide, children }: Props): ReactElement {
  return hide ? <Box /> : <Box>{children}</Box>;
}
